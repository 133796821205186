import { Component } from "./component";
import { asyncDebounce } from "./utils";

export default class ButtonFav extends Component {
  constructor(id, props) {
    super(id, props);
  }

  init(props) {
    super.init(props);

    this.$object = document.createElement("span");
    this.$object.classList.add("icon");
    this.$object.innerHtml = `
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
    </svg>`;

    this.$object.addEventListener(
      "click",
      asyncDebounce(() => this.click(), 150)
    );
  }

  click() {
    console.log("CLICKED");
  }
}
